<template>
  <div class="teamCardList">
    <div class="noCard" v-if="cardList.length == 0">
      <img src="@static/vip/noCard.png" alt="">
      <p>暂无车队卡</p>
    </div>
    <template v-else>
      <div v-for="(item, index) in cardList" :key="index" class="cardCont" @click="toDetail(item)">
        <span>{{item.card.name}}</span>
        <span>{{item.card_num}}</span>
        <div class="balance">
          <span>{{item.balance}}</span>
          <span>元</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import creditshop from "@/api/creditshop";
export default {
  data() {
    return {
      cardList: [],
      my_cardInfor: {}
    };
  },
  filters: {
    setColor: function(val) {
      switch (val) {
        case "0":
          return "linear-gradient(45deg,#34B050,#85CF2D)";
        case "1":
          return "linear-gradient(45deg,#5961F7,#654EA3)";
        case "2":
          return "linear-gradient(45deg,#FF9645,#FFC517)";
        case "3":
          return "";
        default:
          break;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getList();
    });
  },
  methods: {
    async getList() {
      try {
        const res = await creditshop.send_card_list(this.$route.query.id);
        if (res.code == 200) {
          console.log(res);
          if (res.result.length > 0) {
            this.cardList = res.result;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    toDetail(item) {
      if (this.$route.query.hasOwnProperty('type')) {
        this.$router.push("/storedValue?id=" + item.type_card_id + "&cardIdx=" + item.id);
      } else {
        this.$router.push("/sendCard?id=" + item.id + "&cardName=" + item.card.name);
      }
    }
  }
};
</script>


<style lang="scss" scoped>
.teamCardList {
  height: 100vh;
  background: #fff;
  padding: 15px 10px 0;
  position: relative;

  // .cardCont:nth-child(2n + 1) {
  //   background: linear-gradient(45deg, #ff9645, #ffc517);
  // }

  .noCard {
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      color: #999;
      font-size: 16px;
    }
  }

  .cardCont {
    width: 100%;
    height: 110px;
    border-radius: 10px;
    padding: 30px 24px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
    position: relative;
    background: linear-gradient(45deg, #CDB380,#f3debb);

    span:nth-child(1) {
      font-size: 20px;
    }

    .balance {
      position: absolute;
      right: 24px;
      bottom: 24px;

      span:nth-child(1) {
        font-size: 24px;
        margin-right: 4px;
      }
    }
  }

  .addBtn {
    width: 300px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    position: absolute;
    bottom: 10px;
    left: 50%;
    margin-left: -150px;
    background: linear-gradient(90deg, #85cf2d, #34b050);
    border-radius: 5px;
  }
}
</style>